/* eslint-disable no-nested-ternary */
import React, { useMemo, useState } from 'react';

import { ChevronRight, ChevronLeft } from 'tabler-icons-react';
import { get } from 'lodash';
import {
  TableContainer,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  ActionButton,
  PaginationContainer,
  PaginationLabel,
  SelectContainer,
  ExcelButtonContainer,
} from '@components/Table/style';
import Select from '@components/Select';
import useBreakpoint from '@root/hooks/useBreakpoint';
import { StyledTooltip } from '@styles/globalStyles';
import ExcelDownload from '@components/ExcelDownload';
import List from '@components/List';

export const ActionsCell = ({ actions, row, column }) => (
  <TableCell
    className="actions"
    maxWidth={column.maxWidth}
    key={`table-cell-${column.id}-${row.id}`}
  >
    {actions.map(({ icon, label, onClick }, index) =>
      icon && icon(row) !== null ? (
        <ActionButton
          key={`action-${column.id}-${row.id}-${index}`}
          onClick={() => onClick(row)}
        >
          {icon ? icon(row) : label}
        </ActionButton>
      ) : null,
    )}
  </TableCell>
);

const ColumnsSelectWithExcel = ({
  columns,
  setColumnsInTable,
  columnsInTable,
  withExcel,
  excelProps,
}) => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const options = useMemo(
    () => columns.map(({ id, label }) => ({ value: id, label })),
    [columns],
  );
  return (
    <>
      <SelectContainer className={withExcel ? '' : 'left'}>
        <Select
          isMulti
          placeholder="Show/hide columns"
          options={options}
          onChange={(values) => {
            setColumnsInTable(values);
            setMenuIsOpen(true);
          }}
          onBlur={() => setMenuIsOpen(false)}
          value={columnsInTable}
          disableSearch
          controlShouldRenderValue={false}
          hideSelectedOptions={false}
          isClearable={false}
          closeMenuOnSelect={false}
          menuIsOpen={menuIsOpen}
          onMenuOpen={() => setMenuIsOpen(true)}
          onMenuClose={() => setMenuIsOpen(false)}
        />
      </SelectContainer>
      {withExcel && excelProps.excelFileName ? (
        <ExcelButtonContainer className="left">
          <ExcelDownload
            onExcelDownload={excelProps.onExcelDownload}
            name={excelProps.excelFileName}
            data={excelProps.excelData}
            isLoading={excelProps.isLoading}
            keys={
              excelProps.useExcelColumns ? excelProps.excelColumns : columns
            }
            forTable
            valueKey="id"
          />
        </ExcelButtonContainer>
      ) : null}
    </>
  );
};

const Table = ({
  columns,
  data,
  actions,
  rowsPerPageOptions = [10, 50, 100],
  rowsPerPage,
  page,
  onChangePage,
  onChangeRowsPerPage,
  count,
  columnActions,
  big,
  disablePagination,
  showHideColumns,
  withExcel,
  excelProps,
}) => {
  const { BREAKPOINTS, matchBreakpoint } = useBreakpoint();
  const isMobile = matchBreakpoint(BREAKPOINTS.MD);
  const [columnsInTable, setColumnsInTable] = useState(
    columns
      .filter(({ hide }) => !hide)
      .map(({ id, label }) => ({ value: id, label })),
  );

  const filteredColumnsSet = useMemo(
    () => new Set(columnsInTable.map(({ value }) => value)),
    [columnsInTable],
  );

  const filteredColumns = isMobile
    ? columns
    : columns.filter(({ id }) => filteredColumnsSet.has(id));

  if (isMobile) {
    return (
      <List
        isMobile
        fields={columns}
        data={data}
        currentPage={page + 1}
        dataCount={count}
        onPageChange={(value) => onChangePage(value - 1)}
        pageSize={rowsPerPage}
        withExcel={withExcel}
        excelProps={excelProps}
        listTopOffset={70}
        columnActions={columnActions}
      />
    );
  }
  const maxPage = Math.floor(count / rowsPerPage);
  const pageStart = rowsPerPage * page;
  const pageEnd = page === maxPage ? count : rowsPerPage * (page + 1);

  return (
    <TableContainer>
      <TableHeader className={big ? 'big' : ''}>
        {filteredColumns.map(({ id, label, maxWidth, minWidth }) => (
          <TableHeaderCell maxWidth={maxWidth} minWidth={minWidth} key={id}>
            {label}
          </TableHeaderCell>
        ))}
      </TableHeader>
      {data && data.length && filteredColumns.length
        ? data.map((row, index) => (
            <TableRow
              className={big ? 'big' : ''}
              key={`row${row.id || index}`}
            >
              <StyledTooltip />
              {filteredColumns.map((column) => {
                const value = get(row, column.id);
                if (column.id === 'actions' && actions) {
                  return (
                    <ActionsCell
                      key={`action-cell-${column.id}-${row.id}`}
                      actions={actions}
                      row={row}
                      column={column}
                    />
                  );
                }
                let tooltip = null;
                if (column.toolTip) {
                  tooltip = column.format ? column.format(value) : value;
                }
                return (
                  <TableCell
                    className={
                      column.image
                        ? `${column.className}${value.toLowerCase()}`
                        : column.className
                    }
                    clickable={!!get(columnActions, column.id) || undefined}
                    maxWidth={column.maxWidth}
                    minWidth={column.minWidth}
                    align={column.align || 'flex-start'}
                    onClick={() =>
                      get(columnActions, column.id)
                        ? get(columnActions, column.id)(row)
                        : null
                    }
                    key={`table-cell-${column.id}-${row.id}`}
                    data-tip={tooltip}
                    data-place="left"
                  >
                    {column.image
                      ? null
                      : column.format
                      ? column.format(value)
                      : value}
                  </TableCell>
                );
              })}
            </TableRow>
          ))
        : null}
      {disablePagination ? null : (
        <PaginationContainer>
          <ColumnsSelectWithExcel
            columns={columns}
            columnsInTable={columnsInTable}
            setColumnsInTable={setColumnsInTable}
            withExcel={withExcel}
            excelProps={excelProps}
          />
          <PaginationLabel>Rows per page:</PaginationLabel>
          <SelectContainer className="small">
            <Select
              isClearable={false}
              onChange={(option) => onChangeRowsPerPage(option.value)}
              value={{ label: rowsPerPage, value: rowsPerPage }}
              options={rowsPerPageOptions.map((value) => ({
                value,
                label: value,
              }))}
            />
          </SelectContainer>
          <PaginationLabel>
            {pageStart + 1}-{pageEnd} of {count}
          </PaginationLabel>
          <PaginationLabel
            className={page === 0 ? 'disabled' : 'cursor'}
            onClick={() => (page >= 1 ? onChangePage(page - 1) : null)}
          >
            <ChevronLeft />
          </PaginationLabel>
          <PaginationLabel
            className={page === maxPage ? 'disabled' : 'cursor'}
            onClick={() => (page !== maxPage ? onChangePage(page + 1) : null)}
          >
            <ChevronRight />
          </PaginationLabel>
        </PaginationContainer>
      )}
      {disablePagination && showHideColumns ? (
        <PaginationContainer>
          <ColumnsSelectWithExcel
            columns={columns}
            columnsInTable={columnsInTable}
            setColumnsInTable={setColumnsInTable}
            withExcel={withExcel}
            excelProps={excelProps}
          />
        </PaginationContainer>
      ) : null}
    </TableContainer>
  );
};

export default Table;
