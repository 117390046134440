import React, { useState, useEffect, useCallback } from 'react';
import { inject, observer } from 'mobx-react';
import { ContentWrapper } from '@styles/globalStyles';
import Table from '@components/Table';
import Preloader from '@components/Preloader';
import Filters from '@components/Filters';
import {
  tableColumns,
  filtersFunc,
  defaultSearchQuery,
} from '@containers/HealthCheckForSources/constants';
import { useCustomSearchParams } from '@root/hooks/useCustomSearchParams';

const HealthCheckForSources = ({
  healthCheckForSourcesStore: { fetchTable, tableData, isLoadingTable },
}) => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const [title, setTitle] = useState('');

  const [searchQuery, setSearchQuery] =
    useCustomSearchParams(defaultSearchQuery);

  const indexOfLastItem = (page + 1) * rowsPerPage;
  const indexOfFirstItem = indexOfLastItem - rowsPerPage;
  const filteredItems = tableData.filter((x) =>
    x.title.toLowerCase().includes(title.toLowerCase()),
  );
  const currentItems = filteredItems.slice(indexOfFirstItem, indexOfLastItem);

  useEffect(() => {
    fetchTable();
    setSearchQuery(defaultSearchQuery);
    setRowsPerPage(15);
    setPage(0);
  }, []);

  const handleChangePage = async (newPage) => {
    const totalPages = Math.ceil(filteredItems.length / rowsPerPage);
    setPage(totalPages === page + 1 && newPage > page ? page : newPage);
  };

  const handleChangeRowsPerPage = async (value) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const clear = () => {
    setSearchQuery(defaultSearchQuery);
    setPage(0);
    setTitle('');
  };

  const filters = filtersFunc();

  const filterOnSearch = useCallback(() => {
    setTitle(searchQuery.title);
  }, [searchQuery, rowsPerPage, page]);

  return (
    <ContentWrapper>
      {isLoadingTable && <Preloader overlay />}
      <Filters
        clear={clear}
        search={() => {
          handleChangePage(0);
          filterOnSearch();
        }}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) =>
          setSearchQuery({ ...searchQuery, ...valueObj })
        }
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
      />

      <Table
        count={filteredItems.length}
        rowsPerPageOptions={[15, 50, 100]}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        columns={tableColumns}
        data={currentItems}
      />
    </ContentWrapper>
  );
};

export default inject('healthCheckForSourcesStore')(
  observer(HealthCheckForSources),
);
