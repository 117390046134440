const routes = {
  auth: {
    logout: '/',
  },
  dashboard: '/dashboard',
  healthCheck: '/health-check',
  users: '/users',
  contentPublication: {
    index: '/content-publications',
    show: (id) =>
      id ? `/content-publication/${id}` : '/content-publication/:id',
  },
  articlesAndClicks: '/articles-clicks',
  emails: '/emails',
  archivedArticles: '/archived-articles',
  sentiments: '/sentiments',
  articleSourcesMatrix: '/article-sources-matrix',
  companiesRelations: '/companies-relations',
  analystsStatistics: '/analyst-statistics',
  alertsStatistics: '/alerts-statistics',
  articleAggregates: '/article-aggregates',
  summaryReports: {
    index: '/summary-reports',
    show: (id) => (id ? `/summary-report/${id}` : '/summary-report/:id'),
  },
  contentReports: {
    index: '/content-reports',
    show: (id) => (id ? `/content-report/${id}` : '/content-report/:id'),
  },
  newspapers: '/newspapers',
  notificationReports: '/notification-reports',
  nlaAggregates: {
    index: '/nla-aggregates',
    show: (id) => (id ? `/nla-aggregate/${id}` : '/nla-aggregate/:id'),
  },
  clientCoverages: {
    index: '/client-coverages',
    show: (id) => (id ? `/client-coverage/${id}` : '/client-coverage/:id'),
  },
  aasReportRequest: '/aas-report',
  briefingTypesReport: '/briefing-types-report',
  sourcesAttachedToPhrases: '/sources-attached-to-phrases',
  healthCheckForSources: '/health-check-for-sources',
};

export default routes;
