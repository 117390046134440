import React, { useEffect, useMemo, useState } from 'react';
import { inject, observer } from 'mobx-react';
import { useTheme } from '@emotion/react';
import Filters from '@components/Filters';
// eslint-disable-next-line import/no-cycle
import { filtersFuncSectors } from '@containers/AlertsStatistics/constants';
import Text, { TAGS } from '@components/Text';
import { ChartHeader } from '@containers/AlertsStatistics/styles';
import Chart from '@containers/AlertsStatistics/components/Chart';
import Preloader from '@components/Preloader';

const AlertsPerSector = ({
  alertsStatisticsStore: {
    fetchSectors,
    isLoadingSectors,
    sectors,
    fetchCharts,
    dailyData,
    hourlyData,
    clearStore,
    isLoadingCharts,
  },
}) => {
  const [searchQuery, setSearchQuery] = useState({
    timeframe: 'week',
    timeframe_select_option: { label: 'this week', value: 'week' },
  });
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    fetchSectors();
    return clearStore;
  }, []);

  const filters = filtersFuncSectors(sectors, isLoadingSectors);

  const search = (newValue) => {
    fetchCharts({ ...searchQuery, ...newValue }, 'sector');
  };

  const keys = useMemo(() => {
    if (searchQuery.sectorId) {
      const sector = sectors.find(
        ({ value }) => value === searchQuery.sectorId,
      );
      return [
        {
          key: sector.value.toString(),
          label: sector.label,
          color: theme.colors.primary,
          show: true,
        },
      ];
    }
    return [];
  }, [searchQuery]);

  const sectorName = keys[0] ? keys[0].label.substring(0, 17) : '';
  return (
    <>
      <Filters
        clear={() => {}}
        search={search}
        searchQuery={searchQuery}
        setSearchQuery={(valueObj) => {
          setSearchQuery({ ...searchQuery, ...valueObj });
        }}
        isOpen={isFiltersOpen}
        setIsOpen={setIsFiltersOpen}
        filters={filters}
        containerClass="no-margin"
        autoFilter
      />
      {isLoadingCharts ? <Preloader overlay /> : null}

      {!(dailyData.length && hourlyData.length) &&
      keys.length &&
      !isLoadingCharts ? (
        <ChartHeader>
          <Text tag={TAGS.H1}>No data for given filters</Text>
        </ChartHeader>
      ) : null}

      {dailyData.length && keys.length ? (
        <Chart
          companiesNames={sectorName}
          data={dailyData}
          keys={keys}
          dateFormat="DD-MM-YYYY"
          excelKeys={[{ key: 'date', label: 'Date' }, ...keys]}
          label="Daily"
        />
      ) : null}

      {hourlyData.length && keys.length ? (
        <Chart
          companiesNames={sectorName}
          data={hourlyData}
          keys={keys}
          dateFormat="DD-MM-YYYY hh:mm"
          excelKeys={[
            { key: 'date', label: 'Date', format: 'dd-mm-yyyy h:mm' },
            ...keys,
          ]}
          label="Hourly"
        />
      ) : null}
    </>
  );
};
export default inject('alertsStatisticsStore')(observer(AlertsPerSector));
