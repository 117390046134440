import { toast } from 'react-toastify';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';

export class AASReportRequestStore {
  // eslint-disable-next-line class-methods-use-this
  requestReport = async (query) => {
    try {
      await API.get(apiRoutes.aasReport, { params: query });
      toast.success('Email generation requested, it should be send soon!');
    } catch (e) {
      log(e.message, e, TYPE.ERROR);
      toast.error(`Error on attempt to send email:\n\n${e.message}`);
    }
  };
}

export default new AASReportRequestStore();
