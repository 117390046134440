import { makeAutoObservable } from 'mobx';
import API from '@app/api';
import apiRoutes from '@app/apiRoutes';
import { log, TYPE } from '@utils/logger';
import { getDatesFromTimeframe } from '@utils/alertsStatistics';
import { CHART_COLORS } from '@root/constants/common';

export class AlertsStatisticsStore {
  isLoadingCompanies = false;

  companies = [];

  dailyData = [];

  hourlyData = [];

  isLoadingSectors = false;

  sectors = [];

  isLoadingCharts = false;

  shibiSectors = {};

  constructor() {
    makeAutoObservable(this);
  }

  fetchCompanies = async () => {
    this.isLoadingCompanies = true;
    try {
      const {
        data: { data: companies },
      } = await API.get(apiRoutes.alertsStatistics.companies);

      const {
        data: {
          data: { colors: companiesColors },
        },
      } = await API.get(apiRoutes.alertsStatistics.companiesColors);

      this.companies = companies
        .map((company, index) => ({
          value: company.id,
          label: `${company.name} (${company.id})`,
          color: companiesColors[company.id] || CHART_COLORS[index % 33],
        }))
        .sort((a, b) => a.label.localeCompare(b.label));
    } catch (e) {
      log(e, TYPE.ERROR);
    } finally {
      this.isLoadingCompanies = false;
    }
  };

  fetchSectors = async () => {
    this.isLoadingSectors = true;
    try {
      const {
        data: { data: sectors },
      } = await API.get(apiRoutes.alertsStatistics.sectors);

      if (Object.keys(this.shibiSectors).length === 0) {
        const {
          data: { sectors: shibiSectors },
        } = await API.get(apiRoutes.alertsStatistics.shibiSectors);
        this.shibiSectors = {};
        shibiSectors.forEach(({ id, name }) => {
          this.shibiSectors[id] = name;
        });
      }

      this.sectors = sectors
        .reduce((acc, { id, name }) => {
          if (this.shibiSectors[id] === name) {
            acc.push({
              value: id,
              label: `${name} (${id})`,
            });
          }
          return acc;
        }, [])
        .sort((a, b) => a.label.localeCompare(b.label));
    } catch (e) {
      log(e, TYPE.ERROR);
    } finally {
      this.isLoadingSectors = false;
    }
  };

  fetchCharts = async (query, target) => {
    this.isLoadingCharts = true;
    if ((query.companies?.length || query.sectorId) && query.timeframe) {
      await this.fetchIntervalData(query, target, 'daily', 'dailyData');
      await this.fetchIntervalData(query, target, 'hourly', 'hourlyData');
    }
    this.isLoadingCharts = false;
  };

  fetchIntervalData = async (searchQuery, target, interval, collectionKey) => {
    const query = getDatesFromTimeframe(searchQuery.timeframe);
    try {
      if (target === 'company') {
        const chartData = await Promise.all(
          searchQuery.companies.map(({ value }) => {
            query.companyId = value;
            return this.fetchData(query, target, interval);
          }),
        );

        this[collectionKey] = this.prepareCombinedCompaniesData(
          chartData,
          searchQuery.companies,
        );
      } else {
        query.sectorId = searchQuery.sectorId;
        const data = await this.fetchData(query, target, interval);
        this[collectionKey] = data.map(
          ({ timeFrom, sectorId, numberOfAlerts }) => ({
            date: timeFrom,
            [sectorId]: numberOfAlerts,
          }),
        );
      }
    } catch (error) {
      log(error, TYPE.ERROR);
    }
  };

  // eslint-disable-next-line class-methods-use-this
  prepareCombinedCompaniesData = (dataByCompanies, companies) => {
    const dataByCompaniesFlatten = dataByCompanies.flat();

    const emptyItem = {};
    companies.forEach(({ value }) => {
      emptyItem[value] = 0;
    });

    const accumulator = {};

    dataByCompaniesFlatten.forEach((item) => {
      const { timeFrom, numberOfAlerts, companyId } = item;
      const existingObject = accumulator[timeFrom];

      if (existingObject) {
        existingObject[companyId] = numberOfAlerts;
      } else {
        accumulator[timeFrom] = { ...emptyItem };
        accumulator[timeFrom][companyId] = numberOfAlerts;
      }
    });

    return Object.keys(accumulator).map((timeFrom) => ({
      date: timeFrom,
      ...accumulator[timeFrom],
    }));
  };

  // eslint-disable-next-line class-methods-use-this
  fetchData = async (query, target, interval) => {
    const {
      data: { data },
    } = await API.get(apiRoutes.alertsStatistics.charts[target][interval], {
      params: query,
    });
    return data;
  };

  clearStore = () => {
    this.companies = [];
    this.dailyData = [];
    this.hourlyData = [];
    this.sectors = [];
  };
}

export default new AlertsStatisticsStore();
