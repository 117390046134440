import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { parse } from 'query-string';
import moment from 'moment';

const prepareQuery = (objectQuery) => {
  const preparedSearchQuery = { ...objectQuery };
  Object.keys(preparedSearchQuery).forEach((key) => {
    if (preparedSearchQuery[key] instanceof Date) {
      preparedSearchQuery[key] = moment(preparedSearchQuery[key]).toISOString();
    }
    if (typeof preparedSearchQuery[key] === 'object') {
      preparedSearchQuery[key] = JSON.stringify(preparedSearchQuery[key]);
    }
    if (preparedSearchQuery[key] === undefined) {
      delete preparedSearchQuery[key];
    }
  });
  return preparedSearchQuery;
};

const parseQuery = (query) => {
  const parsedQuery = parse(new URLSearchParams(query).toString());
  Object.keys(parsedQuery).forEach((key) => {
    if (moment(parsedQuery[key], 'YYYY-MM-DDTHH:mm:ss.SSSZ', true).isValid()) {
      parsedQuery[key] = moment(parsedQuery[key]).toDate();
    }
    if (parsedQuery[key][0] === '{' || parsedQuery[key][0] === '[') {
      parsedQuery[key] = JSON.parse(parsedQuery[key]);
    }
  });
  return parsedQuery;
};

export const useCustomSearchParams = (defaultSearchQuery) => {
  const [searchQuery, setSearchQueryBase] = useSearchParams();

  const setSearchQuery = (newSearchQuery) => {
    const preparedSearchQuery = prepareQuery(newSearchQuery);
    setSearchQueryBase(preparedSearchQuery);
  };

  useEffect(() => {
    const search = parseQuery(searchQuery);
    if (Object.keys(search).length === 0) {
      setSearchQuery(defaultSearchQuery);
    }
  }, []);

  const getObjectSearch = () => {
    const search = parseQuery(searchQuery);
    if (Object.keys(search).length === 0) {
      return defaultSearchQuery;
    }
    return search;
  };

  const getCleanSearchQuery = () => {
    const search = parseQuery(searchQuery);
    Object.keys(search).forEach((key) => {
      if (typeof search[key] === 'string') {
        search[key] = search[key].trim();
      }
    });
    setSearchQuery(search);
    return search;
  };

  return [getObjectSearch(), setSearchQuery, getCleanSearchQuery];
};
